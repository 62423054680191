import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function Resilience() {
  return (
    <Layout pageTitle="Resilience Strategy">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Belfast Resilience Strategy - USI Projects</title>
        <meta name="description" content="USI have been supporting the Belfast City Council Resilience team in launching the Resilience Strategy for Belfast."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-resilience">
       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Belfast Resilience Strategy</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Belfast City Council</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 class="text-6xl font-heading text-blue-400">Overview</h3>
                <p
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl  ">Belfast is
                  one of the original 100 Resilient Cities launched by the Rockefeller Foundation to help cities build
                  resilience to the physical, social, and economic challenges that are a growing part of the 21st
                  century.<br/><br/>

                  USI have been supporting the Belfast City Council Resilience team in launching the draft strategy, and
                  through significant public and stakeholder engagement, develop and amend the final strategy to meet
                  the needs of the citizens of Belfast. 
                </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase ">Fields of
                  Practice</p>
                <p
                  class="my-4 text-gray-500 leading-relaxed mt-0">Community<br/>
                  Resilience<br/>Climate<br/>Policy & Strategy<br/>Active Citizenship</p>
                <p class="text-2xl font-bold uppercase   mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed   mt-0">Citizen
                  Engagement<br/>
                  Pop-up installations<br/>Strategy Recommendations</p>
                <p class="text-2xl font-bold uppercase    mt-8">Visit</p>
                <a href="https://yoursay.belfastcity.gov.uk/resilience-strategy">
                  <p class="my-4 text-gray-500 leading-relaxed mt-0">
                    resilience-strategy
                  </p>
                </a>

              </div>
            </div>
          </section>
        </div>
      </section>



      <img class="w-full mt-32" src="https://ik.imagekit.io/usi/project-pages/21_Resilience_Strategy_4_1_MalX5cfqT.png"
        alt=""/>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="w-full md:px-40 xl:w-1/2 py-4 xl:px-32 mb-4 md:mb-0">


                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16 ">
                  Following the public launch by the Lord Mayor at Portview Trade Centre, an old spinning mill and
                  heritage building overlooking the city in February, we have since gone on to engage over 750 people
                  through events, workshops and pop-ups across the city.<br/><br/>

                  As well as strategic workshop sessions, we have also created a public art installation, the
                  ‘Resilience Tree’ that has popped up at locations across the city to share the key priorities from the
                  strategy and ask the public to voice their aspirations for the future of the city.
                </p>

              </div>
              <div data-scroll data-scroll-speed="3" data-scroll-class="scroll-class" data-scroll-repeat="true"
                class="w-full md:w-9/12 md:m-auto xl:w-1/2 py-4 sm:px-8 md:px-24 mb-4 md:mb-0 ">
                <img src="https://ik.imagekit.io/usi/project-pages/21_Resilience_Strategy_2_1_V9jslU5dNdRgO.png"/>

              </div>
            </div>
          </section>
        </div>
      </section>



      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full md:pl-20 md:pr-20"
        src="https://ik.imagekit.io/usi/project-pages/21_Resilience_Strategy_1_1__1__DSVQhJBLRZKB.png" alt=""/>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="w-full md:px-40 xl:w-2/4 py-4 xl:px-32 mb-4 md:mb-0">

                <img data-scroll data-scroll-speed="3" src="https://ik.imagekit.io/usi/project-pages/20200214_111613_1__1__VJOWGE4MF.png"/>

              </div>
              <div data-scroll data-scroll-speed="1" class="w-full xl:w-2/4 py-4 sm:px-8 md:px-24 mb-4 md:mb-0">
                <div class="md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16">
                  <h2 data-scroll data-scroll-speed="1"
                    class="text-5xl leading-tight font-semibold font-heading text-blue-500">The key themes of the
                    strategy are about</h2>
                  <ul data-scroll data-scroll-speed="1" class="mt-5 ml-8">
                    <li class="text-xl md:text-2xl ">&nbsp;How we can future proof Belfast</li>
                    <li class="text-xl md:text-2xl  mt-4">&nbsp;The 14 shocks and stresses facing Belfast</li>
                    <li class="text-xl md:text-2xl  mt-4">&nbsp;Climate Resilience</li>
                    <li class="text-xl md:text-2xl  mt-4">&nbsp;Children and Young People</li>
                    <li class="text-xl md:text-2xl  mt-4">&nbsp;Connectivity</li>
                  </ul>


                </div>
             
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-500">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../maritime" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
